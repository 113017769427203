.follow-button {
  border: none;
  position: relative;
  background-color: unset; /* Updated to unset */
  display: flex;
  justify-content: center;
  align-items: center;
}
.follow-button .text__icon {
  display: flex;
  align-items: center;
}
.follow-button .text__icon svg {
  width: 20px;
  height: 20px;
}

.follow-button span {
  z-index: 1;
  display: inline-block;
  border-radius: 25px;
  font-size: 18px;
  text-align: center;
  transition: all 0.5s;
  width: fit-content;
  padding: 0px 10px;
}
.social-icons-container a {
}

.social-icons-container {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: opacity 0.4s ease-in-out;
  gap: 10px; /* Added gap */
}

.follow-button:hover .social-icons-container {
  opacity: 1;
}
