body {
  margin: 0;
  font-family: BoldFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* cursor:none; */
}

a,
button:hover {
  cursor: pointer;
}
.overflow_hidden{
overflow: hidden;
}

.white-background {
  background-color: var(--light);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background-color: var(--dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light);
  border-radius: 5px;
}

@font-face {
  font-family: "BoldFont";
  src: url("../public/mona-sans-cufonfonts-webfont/Mona-Sans-ExtraBoldWide.woff");
}

@font-face {
  font-family: "LightFont";
  src: url("../public/mona-sans-cufonfonts-webfont/Mona-Sans-LightWide.woff");
}
@font-face {
  font-family: "ParagraphFont";
  src: url("../public/mona-sans-cufonfonts-webfont/Mona-Sans-UltraLightWide.woff");
}
@font-face {
  font-family: "ButtonFont";
  src: url("../public/mona-sans-cufonfonts-webfont/Mona-Sans-MediumWide.woff");
}

.full_row {
  width: 100% !important;
  overflow-x: clip;
}
p {
  font-family: ParagraphFont;
  line-height: 35px;
}
h1 {
  font-family: LightFont;
}
:root {
  --light: #f1f1f1;
  --dark: #0c0c0c;
}

.wrap {
  background: var(--dark);
}

.section {
  height: 100vh;
  align-items: center;
  display: flex;
}

.color-dark {
  color: var(--light);
}

.hidden {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 2s ease-out, transform 2s ease-out;
}

.fromright {
  opacity: 1;
  transform: translateX(-100px);
  transition: opacity 2s ease-out, transform 2s ease-out;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.button_web_dark {
  position: relative;
  margin: auto;
  padding: 15px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  position: relative;
  font-family: ButtonFont;
  color: var(--dark);
  font-size: 15px;
  letter-spacing: 3px;
  will-change: transform;
  transform-origin: center;
  mix-blend-mode: difference;
}

.button_web_dark:hover {
  color: var(--light);

}
.button_web_dark:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  backdrop-filter: invert(100%);
  transform-origin: center;
  mix-blend-mode: difference;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
  background: #acacac;

}

.button_web_dark:hover:before {
  width: 100%;


  background: var(--dark);
}

.button_web {
  position: relative;
  margin: auto;
  padding: 15px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  color: var(--light);
  position: relative;
  font-family: ButtonFont;
  font-size: 15px;
  letter-spacing: 3px;
  will-change: transform;
  /* backdrop-filter: invert(100%); */
  transform-origin: center;
  mix-blend-mode: difference;
}

.button_web:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  will-change: transform;
  transform-origin: center;
  mix-blend-mode: difference;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
  background: var(--light);
}

.button_web:hover:before {
  width: 100%;
  background: var(--light);
}

.button_web:active {
  transform: scale(0.95);
}

.brackets {
  font-size: calc(1.5rem + 2.5vw);
  margin: auto 5px;
  background-clip: text;
  mix-blend-mode: difference;
  display: inline-block;
}

.bouncing-element {
  animation: moveUpDown 3.5s infinite linear;
}

.title_center {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
}

span {
  font-family: LightFont;
}

#cursor {
  z-index: 9999;
  position: fixed;
  pointer-events: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  will-change: transform;
  border: solid 1px black;
  backdrop-filter: invert(100%);
  transform-origin: center;
  mix-blend-mode: difference;
  pointer-events: none;
  cursor: none;
}

.page_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  align-items: center;
  gap: 10px;
}

.page_title h1 {
  font-size: calc(7rem + 5.15vw);
  font-family: BoldFont;
}
.page_title span {
  font-size: calc(7rem + 5.15vw);
}

.togglefixed {
  position: fixed;
  top: 50%;
  z-index: 1111;
}
.cursor_work {
  position: relative;
  display: inline-block;
}

.cursor_work::before {
  content: attr(datatype);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100;
  height: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.31);
  border-radius: 50%;
  padding: 8px;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease; /* Add transition properties */
  animation: bounce 1s infinite;
}

.cursor_work:hover::before {
  width: 130px;
  height: 130px;
  opacity: 1;
}

/* Add bouncing animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  40% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  60% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@media only screen and (max-width: 749px) {
  .page_title {
    height: 60vh;
  }
  .page_title h1 {
    font-size: calc(5rem + 3.15vw);
    font-family: BoldFont;
  }
  .page_title span {
    font-size: calc(5rem + 3.15vw);
  }
  #cursor {

    display: none;
  }
}
