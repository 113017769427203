

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    z-index: 1;
   height: 50vh;
    
  }
  .footer_text{
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    align-items: center;
   margin-top:100px;

  }

 .footer_text h1{
  font-size: calc(1rem + 5.15vw);
font-family: "ParagraphFont";
line-height: calc(1rem + 5.5vw);
}

.footer_text h2{
  font-size: calc(1rem + 5.15vw);
font-family: "BoldFont";
line-height: calc(1rem + 5.5vw);
text-align: center;
}

.copywrite-footer_desktop{
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 100px;
  margin-top:50px;
  
}
.copywrite-footer_mobile{
  display:none
}
.footer_scroll{
  width:20%;
  display: flex;
  justify-content: flex-start;

}
.footer_share{
  width: 20%;
  display: flex;
  justify-content: flex-end;
}
.copywrite{
  width: 60%;
  display: flex;
  justify-content: center;
}

  @media screen and (max-width: 768px) {
.footer{
  flex-direction: column;
  padding: 2rem;
  height: 40vh;
}

.copywrite-footer_desktop{
display: none;
}

.copywrite-footer_mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  }

  .copywrite-footer_mobile .copywrite{
    width: 100%;
    margin-bottom: 10px;;
  }
    }
    .copywrite-footer_mobile .footer_share{
      width:100%;
    justify-content: center;
    margin: 10px auto;
    
    display:flex
    }
    .copywrite-footer_mobile .footer_share> p> div{
      gap: 10px;
display: flex;
    }
   /* tablet */
@media only screen and (max-width: 1439px) and (min-width: 769px) {

  .footer{
    padding: 3rem;
    gap: 10px;
    justify-content: center;
  }
 

}
 

