.faq-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 10rem auto;
}

.faq-item {
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;

  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 7.5px;
}

.faq-item:hover {
  background-color: #1d1d1d35;
}

.faq-item.open {
  background-color: #1d1d1d35;
}

.faq-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;

}

.faq-answer {
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  animation: fade-in 0.3s ease;
  animation-fill-mode: forwards;
  opacity: 0;
  font-family:LightFont ;
  line-height: 20px;
}

.faq-item.open .faq-answer {
  max-height: 500px;
  transition: max-height 0.3s ease;
}

.faq-item.open .faq-answer.fade-animation {
  animation: fade-in 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .faq-container {
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin:auto;
  }

  .faq-item {
    font-size: 13px;
  }
}
