:root {
  --size: clamp(10rem, 1rem + 40vmin, 20rem);
  --gap: calc(var(--size) / 100);
  --duration: 80s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}


.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
margin:10px auto;
  /* gap: var(--gap); */

}

.marquee__group {
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* min-width: 200%;  */
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--left-to-right .marquee__group {
  animation-name: scroll-x;
}

.marquee--right-to-left .marquee__group {
  animation-name: scroll-x-reverse; /* Corrected the animation name for right-to-left marquee */
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }

  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-x-reverse {
  from {
    transform: translateX(var(--scroll-end));
  }

  to {
    transform: translateX(var(--scroll-start));
  }
}

/* Element styles */
.marquee div {

  display: flex;
  place-items: center;
  padding: calc(var(--size) /100);
  border-radius: 0.5rem;
  gap:20px;

}

.card_workcarousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  border-radius: 0.5rem;
 
}

.card_workcarousel img {

  width: 450px;
  height: 100%;
  object-fit: cover; /* or 'contain' based on your preference */
  border-radius: 0.5rem; /* Apply border-radius to the image */
}

/* Parent wrapper */
.wrapper {
  display: flex;
  flex-direction: column;
  /* margin: auto; */
  max-width: 100vw;
  overflow: hidden;
}

@media only screen and (max-width: 749px) {
.card_workcarousel img {

  width: 300px;
  height: 100%;
  object-fit: cover; /* or 'contain' based on your preference */
  border-radius: 0.5rem; /* Apply border-radius to the image */
}
}