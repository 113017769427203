/* Default styles for larger screens */
.changing-text {
  font-size: 115px;
  line-height: 0.9em;
  margin-left: 0.15em;
  margin-right: 0.15em;
  margin-top: 0.15em;
  padding-left: 0.125em;
  padding-right: 0.125em;
  text-transform: capitalize;
  color: var(--light);
  font-family: BoldFont;
}

.bracket {
  font-size: 115px;
  line-height: 1.15;
  font-family: tenso, sans-serif;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #555af2, #55b7f5);
  display: inline-block; /* Ensures the gradient is applied only to the text, not the entire block */
}

/* Media query for tablets */
@media screen and (max-width: 1024px) {
  .changing-text {
    font-size: 50px;
    line-height: 1.2;
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0.1em;
    padding-left: 0.1em;
    padding-right: 0.1em;
  }

  .bracket {
    font-size: 60px;
    line-height: 1.5;
  }
}

/* Media query for mobiles */
@media screen and (max-width: 768px) {
  .changing-text {
    font-size: 35px;
    line-height: 1.2;
    margin-left: 0.05em;
    margin-right: 0.05em;
    margin-top: 0.05em;
    padding-left: 0.05em;
    padding-right: 0.05em;
  }

  .bracket {
    font-size: 40px;
    line-height: 1.5;
  }
}
