.marquee_container_services {
  width: 80%;
  margin: auto;
  text-align: center;
  overflow: hidden;
height: 500px;
display: flex;
flex-direction: column;
gap: 20px;
  -webkit-mask-image: -webkit-linear-gradient(
    transparent 1%, /* Increase space at the beginning */
    black 35%,      /* Adjusted transition point */
    black 65%,      /* Adjusted transition point */
    transparent 100% /* Increase space at the end */
  );
}


.custom_marquee_services {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: inline-block;
  animation: marqueeAnimation 50s linear infinite;
  margin: 0 auto;
  transform: translate3d(0px, 0px, 0px) rotateX(-18.6389deg);
}

@keyframes marqueeAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100% * 10));
  }
}

.marquee_title_services h3 {
  font-size: calc(1rem + 3vw);
}
.marquee_title_services span {
  font-size: calc(1rem + 3vw);
  font-family: ParagraphFont;
}

@media screen and (max-width: 768px) {

  .marquee_title_services h3 {
    font-size: calc(1rem + 0.7vw);
  }
  .marquee_title_services span {
    font-size: calc(1rem + 0.7vw);
  }

  .marquee_container_services {
height: 350px;


  }

 
.custom_marquee_services {

  height: 40px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  /* transform-origin: 50% 50%; */
  display: flex;
  flex-direction: column;
  animation: marqueeAnimation 15s linear infinite;
  margin: 0 auto;
  transform: translate3d(0px, 0px, 0px) rotateX(-18.6389deg);
}
}

@media only screen and (min-width: 1280px)  and (max-width: 1585px) and (orientation: landscape) {

  .marquee_container_services {
    height: 350px;
    
    
      }
}
