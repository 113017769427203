

.navbar_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px ;
  position: sticky;
  /* z-index: ; */
  /* background-color: #0c0c0c; */
}
.img_header{
  width: 15%;
}
.menu{
  display: flex;
  justify-content: flex-end;
}


.icons_header_menu{
display: none;

}
#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}
#navbar li a {
  text-decoration: none;
  font-size: 1.2rem;
  font-family: LightFont;
  mix-blend-mode: difference;
}





#mobile {
  display: none;
}

#mobile i {
  color: #2a3239;
  align-items: center;
}



/* 13 inch */
@media only screen and (min-width: 1440px)  and (max-width: 1585px) and (orientation: landscape) {

.navbar_nav{
  padding: 20px 50px;
}


}
/* tablet */
@media only screen and (max-width: 1439px) and (min-width: 769px) {
.navbar_nav {
  padding: 20px 20px;
}

#navbar li{
  padding: 0 10px;
}

.img_header{
  width: 15%;
}

.slide__info > img {
  bottom: -45%;
}
}


@media screen and (max-width: 768px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    gap: 50px;
    position: fixed;
    top: 0px;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: var(--light);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
    color: var(--dark);
   
    z-index: 11;

  }
  #mobile{
    z-index: 111;
    display: block;
    position: fixed;
    right: 30px;
  }
#navbar.active{
  right:0px;
}


.icons_header_menu{
  display: block;
  color: var(--dark);
  
  }
  #navbar li {
    margin-bottom: 25px;
    color: var(--dark);
  }

  .img_header{
    width: 50%;
  }
  #mobile {
    display: block;
  }

  .navbar_nav{

    padding: 20px 30px;
  }

  #mobile svg {
    font-size: 24px;
    z-index: 1111111;
    position: relative;
  }
  #navbar li a {
      text-decoration: none;
      font-size: 1.2rem;
      color: var(--light);
    }
    #mobile>svg {
      color:  #555af2;
      display: inline-block; 
  
    }
 

}
