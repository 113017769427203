@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 30px 0;
  white-space: nowrap;
  position: relative;
  border-bottom: solid 1px #1d1d1d;
  border-top: solid 1px #1d1d1d;
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-flex;
  animation: slide 100s infinite linear;
  white-space: nowrap;
  animation-timing-function: linear;
}

.logos-slide img {
  height: 35px;
  margin: 0 40px;

  aspect-ratio: 3/1;
  object-fit: contain;
}
