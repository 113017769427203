
.servicesabout_container{
  display: flex;
  flex-direction: column;
  gap:30px;
  width: 70%;
margin:50px auto;
}
.servicesabout{
display: flex;
flex-direction: column;
padding: 20px;
border: 1px solid #ccc;
border-radius:10px;
gap:10px;
}
.servicesabout h3{
  font-size: calc(0.8rem + 1.5vw);
}
.servicesabout span {
  font-size: calc(0.8rem + 1.5vw);
  font-family: ParagraphFont;
}
.servicesabout p{

}

@media only screen and (max-width: 749px) {


  .servicesabout_container{
  
    gap:20px;
    width: 100%;
    padding: 10px;
 
  }

}