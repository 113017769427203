.scroll-to-top {
  left: 20px;
  z-index: 999;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
 
}

.scroll-to-top button {
cursor: pointer;

  height: 100%;
  border: none;
  background-color: transparent;
  color:var(--primary-color);
 
  font-size: 1.5rem;
    display: flex;
    align-items: center;
}

