.page_title.flex {
    display: flex;
    flex-direction: row;
  }
  
  .page_title.flex span,
  .page_title.flex h1 {
    display: inline-block;
    margin: 0;
  }
  
  .page_title.flex {
    animation: marquee 20s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  