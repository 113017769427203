.toggle-theme-button {
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
    background-color: #f0f0f0; /* Light background color */
    border: 1px solid #ccc;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow: hidden;
  }
  
  .toggle-theme-button.dark {
    background-color: #333; /* Dark background color */
  }
  
  .toggle-theme-button input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-theme-button .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dark); /* Background color */
    transition: 0.5s;
    border-radius: 30px;
  }
  
  .toggle-theme-button .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 50%;
    left: 10%;
    bottom: 15%;
    box-shadow: inset 8px -4px 0px 0px var(--light);
    background: var(--dark); /* Background color */
    transition: 0.5s;
  }
  
  .toggle-theme-button input:checked + .slider {
    background-color: var(--dark); /* Checked background color */
  }
  
  .toggle-theme-button input:checked + .slider:before {
    transform: translateX(100%);
    box-shadow: inset 15px -4px 0px 15px var(--light);
  }

  @media only screen and (max-width: 749px) {
  .toggle-theme-button {
 
    right: 40px;
  
  }

  }