.loader {
    display: flex;
    gap: 0.6em;
    list-style: none;
    justify-content: center;
    width: 100%;
  }
  
  .dots {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: transparent;
    background-image: linear-gradient(to right, #555af2, #55b7f5);
    display: inline-block;
  }
  
  #dot-1 {
    animation: loader-1 0.6s infinite ease-in-out;
  }
  
  @keyframes loader-1 {
    50% {
      opacity: 0;
      transform: translateY(-0.3em);
    }
  }
  
  #dot-2 {
    animation: loader-2 0.6s 0.3s infinite ease-in-out;
  }
  
  @keyframes loader-2 {
    50% {
      opacity: 0;
      transform: translateY(-0.3em);
    }
  }
  
  #dot-3 {
    animation: loader-3 0.6s 0.6s infinite ease-in-out;
  }
  
  @keyframes loader-3 {
    50% {
      opacity: 0;
      transform: translateY(-0.3em);
    }
  }