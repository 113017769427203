
.dark-theme{
  background-color: var(--dark);
  color: var(--light);
  
}
.dark-theme a {
  color: var(--light);
  
}
.light-theme a {
  color: var(--dark);

}

.light-theme {
  background-color:var(--light);
  color: var(--dark);
}