.herocontact{
  width: 100%;
  height: 450px;
  object-fit: cover;
  filter: grayscale(50%);
  /* background-image: url("../../Assets/contacthero.jpg"); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 5rem auto;
}
.contact_section{
  width: 100% !important;
  padding:0 !important;
}
.contact_info {
  width: 80%;
  padding: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: -250px auto  auto auto;
  text-align: center;


}
.contact_card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 6rem;
  background-color: #fff;
  z-index:1;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 12%;
  color: #1c1c1c;
  height: 200px;


}
.contact_faq_container{
width: 100%;
display: flex;
flex-direction: column;
}
.form_contact_container {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 10rem;
  display: flex;
  flex-direction: column;
}

.faq_contactus{
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 10rem;
  display: flex;
  flex-direction: column;
  }
.form_contact {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: flex-start;
  width: 100%;
}

.contact_form_input {
 display: flex;
 flex-direction: column;
  text-align: left;
  row-gap: 20px;
  width: 100%;
}

.contact_form_input > div > input {
  font-size: 15px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 7.5px;
  width: 100%;
  padding: 0 1.1rem;
  font-family: "Exo", sans-serif;

}
.contact_form_input > div > input::placeholder {
  font-family: "Exo", sans-serif;
}





.social_icons_contact {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-size: 1.5rem;
}

.social_icons_contact > a {
  text-decoration: none;
  color: #1c1c1c;
}


.button_contactform{
  width: 100%;
}
.contact_form_input_phoneandmsg{
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: center;
  row-gap: 20px;
  width: 80%;
  margin-left: 1.5rem;

}
.contact_form_input_phoneandmsg > div >input{
  font-size: 15px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 7.5px;
  width: 86%;
  padding: 0 1.1rem;
  font-family: "Exo", sans-serif;

}



/* 13 inch */
@media only screen and (max-width: 1440px) and (orientation: landscape)
{ 
.contact_info{
  width: 95%;
  padding: 0;
}
.contact_form_input{
  margin-left:15px

}
.contact_form_input_phoneandmsg > div >input{
  padding: 0 1rem;
}
.contact_form_input > div > input{
  padding: 0 1rem;

}
}


/* tablet */
@media only screen and (max-width: 1439px) and (min-width: 769px) {
  .herocontact{
    object-fit: none;
    background-attachment: unset;
  }
  .form_contact_container {
width:70%;
margin:auto;
padding: 3rem 3rem;
  }
  .form_contact{
    text-align: center;
  }
  .contact_form_input{
    display: flex;
    flex-direction: column;
    width: 100%;
    
      }
    
      .contact_form_input_phoneandmsg{
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        margin-top: 10px;
        width: 100%;
      }
      .contact_info{
        width: 100%;
        justify-content: space-between;
        padding: 0;
      }

      .contact_card{
        padding:3rem;
        width: 15%;
      }
      .contact_card>h2{
        font-size: 20px;
      }
      .contact_card>p{
        font-size: 12px;
      }
.social_icons_contact{
  font-size: 18px;
}


 

}






@media screen and (max-width: 768px) {
  .contact_container {
    display: block;
    width: 100%;
    margin: 0;
  }
  .herocontact{
    object-fit: none;
    background-attachment: unset;
  }

  .contact_info {
    width: 100%;
    padding: 0px ;
    align-items: center;
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .contact_card {
width: 60%;
row-gap: 20px;
padding: 6rem 3rem;

  }

  .contact_info h1 {
    text-align: center;
    font-size: 25px;
  }
  .form_contact{
    text-align: center;
  }

  .form_contact_container {
    width: 90%;
    padding: 2rem 1rem;
    margin: auto;
  }

  .form_contact h1
  .send_contact_div {
    text-align: center;
  }
  .contact_form_input{
display: flex;
flex-direction: column;

  }

  .contact_form_input_phoneandmsg{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 10px;
  }

  .domain_form .domain_btn, .form_contact .domain_btn{
    width: 100%;
  }
  .faq_contactus {
padding: 10px;

  }
  .faq_contactus .text_section {
   text-align: center;
    
      }
}
