.pricing-palden .pricing-item.pricing__item--featured {
  transform: scale(1.05);
}
.pricing__item--featured .pricing-deco{
    position: relative;
    overflow: hidden;
    background: none !important;
}
.pricing__item--featured .pricing-deco .pricing-title,.pricing__item--featured .pricing-deco .pricing-price{
    color:var(--dark);

}



.pricing__item--featured .pricing-deco::before {
    content: 'Premium';
    position: absolute;
    width: 60%;
    height: 20px;
    top:40%;
    left:-10%;
    background-image: linear-gradient(45deg, #666666 0%, #000000  51%, #3f3f3f  100%);
    transform: rotate(-45deg) translateY(-20px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    box-shadow: 0 5px 10px rgba(0,0,0,0.23);
  }

.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  gap: 10px;
}

.pricing-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  -webkit-flex: 0 1 330px;
  flex: 0 1 330px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.pricing-action:focus {
  outline: none;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-palden .pricing-item {
  cursor: default;
  color: var(--dark);
  font-family: 'LightFont';
  background: #fff;
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 20px 20px 10px 10px;
  margin: 1em;
  font-size: 0.8em;
}



@media screen and (min-width: 66.25em) {
  .pricing-palden .pricing-item {
    margin: 2em -0.5em;
  }
  .pricing-palden .pricing__item--featured {
    margin: 0;
    z-index: 10;
    box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.pricing-palden .pricing-deco {
  border-radius: 10px 10px 0 0;
  background: linear-gradient(360deg, #1d1d1d, #272727);
  padding: 4em 0 2em;
  position: relative;
}

.pricing-palden .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
}

.pricing-palden .pricing-title {
  font-size: 3em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
}

.pricing-palden .pricing-price {
  font-size: 2em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  margin: 0 0 1em 0;
  line-height: 0.75;
}

.pricing-palden .pricing-currency {
  font-size: 1em;
  vertical-align: top;
}

.pricing-palden .pricing-period {
  font-size: 0.5em;
  padding: 0 0 0 0.5em;
  font-style: italic;
  color: var(--primary-color);
}

.pricing-palden .pricing__sentence {
  font-weight: bold;
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
}

.pricing-palden .pricing-feature-list {
  margin: 0;
  padding: 0.25em 0 2.5em;
  list-style: none;
  text-align: center;
}

.pricing-palden .pricing-feature {
  padding: 1em 0;
}

.pricing-palden .pricing-action {
  font-weight: bold;
  margin: auto 3em 2em 3em;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  background: linear-gradient(135deg, #a93bfe, #584efd);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}



.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 5em 0 8.885em 0;
}



.flex {
  /*Flexbox for containers*/
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}


.domain_form{
width: 60%;
margin: auto;
display: flex;
flex-direction: column;

}

.domain_form .input_form{
  width: 100%;
  padding:15px;
  border-radius:10px;
  border:1px solid var(--light);
  justify-content:center;
  align-items: center;
 background-color: transparent;
 box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
 color: var(--light);

}

.light-theme .domain_form .input_form {
  border:1px solid var(--dark);
  color: var(--dark);
}

.light-theme .domain_form .domain_btn,.light-theme .form_contact .domain_btn {

  border:1px solid var(--dark);

}
.domain_form .domain_btn,.form_contact .domain_btn {
  border:none;
  width: 20%;
  margin: 20px auto;
  color: var(--dark);
  font-size: 15px; 
  padding: 10px;
  border-radius: 10px;
  background-color: var(--light);
  transition: transform 0.3s ease-in; 
}
.domain_form  {
  transform: scale(1.1);
}
.hosting_form_container{
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
gap: 20px;;
}
.hosting_form_container p{

  font-size: calc(0.1rem + 1.5vw);
}


.pricing_btn{
  border:1px solid var(--dark);
  width: 80%;
  margin: 20px auto;
  color: var(--light);
  font-size: 15px; 
  padding: 10px;
  border-radius: 10px;
  background-color: var(--dark); 
 transition: transform 1s ease-in; 
}
.pricing_btn:hover{

border:1px solid var(--dark);
color:var(--dark);
background-color: transparent;
}


@media only screen and (max-width: 749px) {


  .domain_form{
width: 90%;
transform: none;
padding: 10px;
  }

  .hosting_form_container p{
font-size: 20px;
  }
  .pricing_btn{
    width: 90%;
  }
  
}
