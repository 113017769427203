.color-light {
  color: var(--dark) !important;
}

.hero {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f1f1f1;
}


.hero p {
  font-size: calc(2rem + 5.15vw);
  font-family: LightFont;
}

.sections_container {
  display: flex;

  align-items: center;
}
.text_section {
  width: 50%;
  padding: 0px 100px;
}
.text_section h1 ,.title_center h1{
  margin-bottom: 20px;
  /* font-size:60px; */
  font-size: calc(1rem + 2.5vw);
}
.text_section p {
    font-size:20px;
    font-size: calc(0.8rem + 0.5vw);
  }
.image_section {
  width: 50%;
  padding: 0px 80px;
}
.image_section img {
  width: 100%;
  filter: drop-shadow(6px 8px 10px  rgba(0, 0, 255, 0.193)); 
 
}

.blink {
  animation: blinker 2s linear infinite;
  font-family: sans-serif;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}
.work_carousel_home{
  position:relative;
}


.page_title.flex {
  display: flex;
  flex-direction: row;
}

.page_title.flex span,
.page_title.flex h1 {
  display: inline-block;
  margin: 0;
}

.page_title.flex {
  animation: marquee 20s linear infinite;
}

.work_carousel_home {
  position: relative;
}

.marquee_right, .marquee_left {
  position: absolute;
  z-index: 0;
 
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.marquee_right {
  /* animation-name: marqueeRight; */
  top: -10%;
  z-index: 1;
}

.marquee_left {
  /* animation-name: marqueeLeft; */
  top: 85%;
}

.work_carousel_home div p {
  font-size: 150px;
  line-height: unset;
  opacity: 0.3;
  font-family: BoldFont;
  color: var(--light);
  -webkit-text-fill-color: var(--dark);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--light);
}


@media screen and (max-width: 768px) {

  .mobile-flip{
    flex-direction: column-reverse !important;
    }
    
  .full_row{
    margin-top: 60px;
    overflow-x: clip;
  }
  .hero {
    height: 70vh;
    
  }
.sections_container{
flex-direction: column;

}
.text_section{
width: 100%;
padding: 10px;
}

.image_section {
  width: 90%;
  padding: 10px;
}
.image_section img{
  width: 90%;
  padding: 10px;
}
}