.work-container {
  
  width: 90%;
  margin:5rem  auto;
}

/* Container for the work grid */
.work-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 4 columns */
  gap: 30px; /* Adjust the gap between cards as needed */
}

/* Style for each work card */
.work-card {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  text-align: left;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(360deg, #1d1d1d, #272727);
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 800px;
  transition: transform 0.3s ease-in; /* Add the transition property */
}

/* Style for the image inside the card */
.work-card img {
  width: 100%;
  height: 300px;
  border-radius: 10px; /* Apply the same border radius as the card */
}

/* Style for the title inside the card */
.work-card h2 {
  margin-top: 10px;
  font-size: 16px;
  color: #fff; /* Set the title color to #171717 */
  position: relative;
}

/* Add hover effect for the card */
.work-card:hover {
  transform: translateY(-5px); 
/* Adjust the distance as needed */
}



.work-card h2::after {
  content: "Live Preview";

  display: block;
  color: var(--primary-color); /* Color for the arrow and "Live Preview" text */
  font-size: 14px;
  position: absolute;
  top: 100%; /* Initially, position it below the card */
  right: 10px;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transition for top and opacity properties */
  opacity: 0; /* Initially, set opacity to 0 to make it invisible */
}

/* On hover, slide in and fade in the "Live Preview" content from the top */
.work-card:hover h2::after {
  top: 3px; /* Slide in from below the card */
  opacity: 1; /* Fade in */
}
/* Tab buttons */
.tab-buttons {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1E1E1E;
  padding: 10px;
  border-radius: 10px;
}

.tab-buttons button {
  background: #171717;
  padding: 10px 20px;
  color: #fff;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 10px; /* Make the buttons rounded */
  transition: background 0.3s, transform 0.3s; /* Add transition for background and transform */
  transform: scale(1); /* Set the initial scale to 1 */
}

.tab-buttons button.active {
  border: 1px solid;
  border-color: var(--primary-color);
  color: #fff;
  transform: scale(1.1); /* Add a scale effect for the active button */
}

.work-container .button_web{
  backdrop-filter: unset;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderbtn{
  mix-blend-mode: unset !important;
}
@media only screen and (max-width: 749px) {
  .tab-buttons {
    width: 100%;

  }
  .tab-buttons button {
    padding: 10px;
    font-size:12px
  }

  .work-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Create 4 columns */
    gap: 30px; /* Adjust the gap between cards as needed */
  }

}